// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}


.accordion-body {

  .card-body{
    background-color: #faf6f6;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;

    h6{
      font-size: 11px;
    }
 
  }
  .card-body:hover{
    background-color: #96c1d7;
    border-radius: 5px;

    h6{
      color:white;
    }
  }

  .card-body:active{
    background-color: rgb(214, 212, 212);
    border-radius: 5px;

    h6{
      color:white;
    }
  }

  .card-surgery-selection.card.border.text-white.bg-primary{
    .card-body{
      background-color: #1A3949;
      h6{
        color:white;
      }
    }

    .card-body:hover{
      h6{
        color:#b7b7b7
      }
      
    }

    .card-body:active{
      background-color: rgb(214, 212, 212);
      border-radius: 5px;
  
      h6{
        color:black;
      }
    }
    
    
   
  }

}
