// estilo personalizado para loading

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    -ms-align-items: center;
    z-index: 999; // Asegura que esté por encima del contenido detrás
   
  }

  .spinner-body{
    font-family: Arial, Helvetica, sans-serif;
    width:  300px;
    padding: 15px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
  }
  
  .spinner-loading {
    background-color: white; // Color de fondo blanco para el cuadro
    padding: 20px; // Espaciado interno para el cuadro
    display: flex;
    flex-direction: column;
    align-items: center; // Centrar contenido verticalmente
    justify-content: center; // Centrar contenido horizontalmente
  }


  .loading-all {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    
  }