//
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    color: $gray-700;
  }

  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons";
    }
  }
}
  