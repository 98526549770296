//
// colorpicker.scss
//

.sp-container {
  background-color: $dropdown-bg;

  button {
    padding: .25rem .5rem;
    font-size: .71094rem;
    border-radius: .2rem;
    font-weight: 400;
    color: $dark;

    &.sp-palette-toggle {
      background-color: $light;
    }

    &.sp-choose {
      background-color: $success;
      margin-left: 5px;
      margin-right: 0;
    }
  }
}

.sp-palette-container {
  border-right: 1px solid $border-color;
}

.sp-input {
  background-color: $input-bg;
  border-color: $input-border-color !important;
  color: $input-color;

  &:focus {
    outline: none;
  }
}


[dir="rtl"] {

  .sp-alpha {
    direction: ltr;
  }

  .sp-original-input-container {
    .sp-add-on {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important
    }
  }

  input.spectrum.with-add-on {
    border: 1px solid $input-border-color;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;

  }
}